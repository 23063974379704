.account-settings-container {
    display: flex;
    flex-direction: column;
    margin-top: 5em;
}

.advanced-settings-button-container {
    display: flex;
    flex-direction: column;
    margin-top: 5em;
    margin-left: 20%;
    gap: 50%;
}

@media screen and (max-width: 500px) {
    .account-settings-container h5 {
        font-size: 3vw;
    }
}