.footer-container {
    border-top: solid 1px;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #212529;
    color: white;
    height: 17em;
    padding-top: 2em;
}

.footer-grid-container {
    display: flex;
    flex-direction: row;
    padding-left: 90px;
    padding-right: 90px;
}

.column-1 {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%;
}

.column-2 {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%;
}

.footer-social-media-container {
    display: flex;
    gap: 1em;
}

.footer-social-media-icon {
    width: 2.5em;
    height: 2.5em;
}

.footer-extra-link-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer-extra-link-container a {
    text-decoration: none;
    color: white;
}

.footer-extra-link-container a:hover {
    text-decoration: underline;
    color: white;
}

@media screen and (max-width: 700px) {
    .footer-grid-container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .column-1 {
        padding: 0;
    }

    .column-2 {
        padding: 0;
    }
}

@media screen and (max-width: 500px) {
    .footer-container {
        height: 24em;
    }

    .footer-grid-container {
        flex-direction: column;
        padding-left: 25%;
    }
    .footer-logo {
        display: none;
    }
    .column-2 {
        padding-top: 20px;
    }
}