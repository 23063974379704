.abonnement-setting-header {
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    gap: 50%;
}

.setting-info-text {
    margin-right: 10%;
}

.monthly-switch {
    margin-top: 3em;
    margin-left: 48%;
}

.subtext {
    margin-top: 2%;
    margin-left: 20%;
}

.card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    gap: 1%;
}

.list-group {
    list-style: none;
}

.list-group li:before {
    content: '\2714\0020';
}

@media (min-width: 1000px){
    .card {
        box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
        transition: transform 0.8s;
        height: 40em !important; 
    }

    .card:hover {
        transform: scale(1.05);
        transition: transform 0.8s;
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }

    .redirect-button{
        position: absolute;
        bottom: 0%;
        right: 27%;
    }
}

@media (max-width: 999px){
    .card-container {
        flex-direction: column;
    }

    .card:hover{
        transform: none;
    }

    .card {
        box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
        transition: none;
        height: auto !important; 
        margin-bottom: 5% !important;
    }
    
    .abonnement-setting-header {
        gap: 10%;
    }

    .redirect-button{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1em;
    }
}

@media screen and (max-width: 500px) {
    .abonnement-setting-header h3 {
        font-size: 5vw;
    }
}