.payment-settings-container {
    display: flex;
    flex-direction: column;
    margin-top: 5em;
}

@media screen and (max-width: 500px) {
    .payment-settings-container h5 {
        font-size: 3vw;
    }
}