.registration-validation-step-header-container {
    margin-top: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5em;
}

.registration-validation-step-header-container h4 {
    color: green;
}

.registration-validation-step-container {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4em;
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
}

.registration-validation-step-container h5 {
    color: red;
}
  
.registration-validation-step-container .ReactInputVerificationCode__item {
    position: relative;
    color: #262626;
    font-weight: 500;
}
  
.registration-validation-step-container .ReactInputVerificationCode__item,
.registration-validation-step-container .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
}
  
.registration-validation-step-container .ReactInputVerificationCode__item:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    background-color: #ebebeb;
    transition: background-color 0.2s ease-out;
}
  
.registration-validation-step-container .ReactInputVerificationCode__item.is-active:after {
    background-color: #046cde;
}
