.css-8atqhb {
    width: 70% !important;
    margin-top: 5%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (max-width: 1200px) {
    .css-m5vj9m-MuiStepper-root { 
        align-items: center !important;
        gap: 0.5em;
        margin-top: 15%;
    }

    .css-1hv8oq8-MuiStepLabel-label {
        display: none !important;
    }
}