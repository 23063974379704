.change-password-setting-header {
    display: flex;
    flex-direction: column;
    margin-top: 5em;
    margin-left: 20%;
    gap: 50%;
}

.successful-changing-password-message {
    color: green;
    margin-left: 20%;
}

.space-to-top {
    margin-top: 2em;
}

@media screen and (max-width: 500px) {
    .change-password-setting-header h3 {
        font-size: 5vw;
    }
}