.change-setting-button-container {
    margin-left: 20%;
    margin-top: 2em;
}

.change-setting-button-container button {
    background-color: var(--tictrack-primary-color);
}

.change-setting-button-container button:hover {
    background-color: var(--tictrack-secondary-color);
}