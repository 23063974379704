.password-reset-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
    padding-right: 5%;
    padding-left: 5%;
    width: 100%;
    padding-bottom: 40em;
    text-align: center;
}

.password-reset-container .mb-3 {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.password-reset-container .form-label {
    width: fit-content;
}

.password-reset-container button {
    margin-top: 3em;
}

.password-reset-container h3 {
    margin-bottom: 3em;
}

.password-reset-container h5 {
    margin-bottom: 3em;
}

.reset-password-change-password-successful {
    color: green;
    display: flex;
    flex-direction: column;

}

.reset-password-change-password-successful button {
    font-size: 1.5em;
}

.reset-password-change-password-successful button img {
    width: 1.3em;
    height: 1.3em;
}

.go-to-login-button-container {
    display: flex;
    flex-direction: column;
}

.reset-password-change-password-error {
    color: red;
}

.to-login-button {
    text-align: center;
}

