.reset-qr-password-container {
    margin-top: 2em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.qr-tablet-change-password-container {
    display: flex;
    gap: 1em;
}

@media screen and (max-width: 1200px) {
    .qr-tablet-change-password-container {
        flex-direction: column;
    }
}