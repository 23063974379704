.time-config-container {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-left: 20%;
}

.time-config-container h5 {
    margin-bottom: 0 !important;
}

@media screen and (max-width: 1200px) {
    .time-config-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .time-config-container {
        display: flex;
        align-items: center;
        gap: 1em;
        margin-left: 0;
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .time-config-container h5 {
        font-size: 3vw;
    }
}