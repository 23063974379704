.change-name-setting-header {
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    gap: 50%;
}

@media screen and (max-width: 500px) {
    .change-name-setting-header h3 {
        font-size: 5vw;
    }
}