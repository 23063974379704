.admin-controller-setting-header {
    display: flex;
    flex-direction: row;
    margin-left: 20%;
    gap: 50%;
}

.password-checklist svg{
    margin-top: 5px;
}

.create-admin-requirements {
    margin-top: 0.4em;
    color: gray;
    font-size: 0.9em;
    line-height: 0.5em;
}

.red {
    color: red;
}