.content {
    flex: 1;
}

.gray-text {
    color: gray;
}

.green-text {
    color: green;
}

.divider-solid {
    margin-left: 13%;
    border-top: 0.1em solid #000000;
    width: 80%;
}

.setting-info-text {
    margin-top: 1em;
    margin-left: 20%;
}