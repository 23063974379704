.admin-list-container {
    display: flex;
    gap: 1em;
    flex-direction: column;
    margin-top: 5em;
    margin-left: 20%;
    cursor: pointer;
}

.admin-list-container h4{
    cursor: default;
}

.admin-list-container a {
    text-decoration: none;
    color: black;
}

.admin-list-container a:hover {
    color: black;
}