/* .qr-settings-page-container {
}
 */
/* .qr-settings-page-container h3, h5 {
    cursor: default;
} */

.qr-config-header {
    display: flex;
    align-items: baseline;
    margin-left: 20%;
    gap: 30%;
    margin-top: 5em;
}

.info-i-img {
    height: 2em;
    width: auto;
}

@media screen and (max-width: 1200px) {
    .qr-config-header {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
}

@media screen and (max-width: 500px) {
    .qr-config-header h3 {
        font-size: 5vw;
    }

    .qr-config-header button {
        font-size: 3.3vw;
    }
}