.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    gap: 2em;
    width: 100%;
}

.mb-3 {
    margin-top: 2em !important;
}

.login-adornments {
    display: flex;
    gap: 7em;
}

.login-button {
    background-color: var(--tictrack-primary-color) !important;
    border-color: #78B4D8 !important;
    margin-top: 2.5em;
    width: 50%;
    height: 3em;
    border-radius: 0.6em 0.6em 0.6em 0.6em !important;
    text-transform: none !important;
    font-size: large !important;
}

.login-button:hover {
    background-color: var(--tictrack-secondary-color) !important;
}

.link-forgot-password {
    padding-top: 0.5em;
    font-size: 0.9em;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: left;
}

.link-forgot-password:hover {
    color: var(--tictrack-primary-color) !important;
}

.login-error-message {
    margin-top: 2em;
    color: red;
}

.successful-reset-password-message {
    display: flex;
    justify-content: center;
    color: green;
    text-align: center;
}

.successful-reset-password-message p {
    max-width: 80%;
}

.forget-password-mail {
    width: 100%;
}

.login-forget-mail-box {
    width: 50em !important;
}

.forget-password-form {
    margin-top: 2em;
}

.forget-password-modal-buttons {
    margin-top: 3em;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1em;
}

@media only screen and (max-width: 800px) {
    .login-adornments {
        gap: 30%;
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px) {
    /* .login-form-container input {
        width: 20em;
    } */
}

@media only screen and (max-width: 300px) {
    /* .login-form-container input {
        width: 10em;
    } */
}