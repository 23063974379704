.create-initial-admin-account-header {
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    gap: 50%;
}

.create-initial-admin-account-info-text {
    margin-left: 15%;
    max-width: 900px;
    padding-right: 10%;
}

.initial-admin-creation-form-container {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.initial-admin-creation-form-container form {
    width: 40em;
}

@media screen and (max-width: 1000px) {
    .initial-admin-creation-form-container {
        margin-left: 10%;
        width: 80%;
        font-size: 3vw;
    }

    .initial-admin-creation-form-container form {
        width: 90%;
    }

    .initial-admin-creation-form-container form div {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .create-initial-admin-account-header h3 {
        font-size: 5vw;
    }

    .create-initial-admin-account-info-text {
        font-size: 3vw;
        padding-right: 10%;
    }
}