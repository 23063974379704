.admin-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em;
    border-radius: 0.6em 0.6em 0.6em 0.6em;
    border: 0.05em solid gray;
    width: 80%;
}

.admin-information-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
}

.design-to-click-to-admin-detail-view {
    display: flex;
    align-items: center;
}

.design-to-click-to-admin-detail-view img {
    width: 2em;
    height: 2em;
}

.admin-container:hover {
    border: 0.07em solid black;
}

.admin-information-container h5 {
    margin-bottom: 0;
    margin-left: 1em;
}

.neutral-user-icon {
    width: 2em;
    height: 2em;
    margin-right: 1em;
}