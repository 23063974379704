.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55em;
    width: 100%;
    margin-bottom: -5em;
}

.login-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em;
    border: 2px solid #000000; 
    position: relative;
    background-color: white;
}

.want-make-an-Account-question {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.want-make-an-Account-question a {
    text-decoration: none;
    color: var(--tictrack-primary-color);
}

.want-make-an-Account-question a:hover {
    color: var(--tictrack-secondary-color);
}

@media screen and (max-width: 1200px) {
    /* .login-field input {
        width: 50%;
    } */

/*     .login-container {
        height: 40em;
    } */
}

@media screen and (max-width: 500px) {
    .login-field{
        width: 100%;
        height: auto;
        border: none;
    }

    .login-container {
        height: auto;
    }

    .login-text {
        font-size: xx-large;
    }
}