.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50em;
    flex-direction: column;
    gap: 1em;
}

.error-page-container a {
    text-decoration: none;
}

.error-header {
    position: absolute;
    background-color: #212529;
    width: 100%;
    height: 5em;
}