.admin-detail-view-header {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    margin-left: 20%;
    gap: 50%;
}

.admin-back-button {
    margin-left: 5%;
    margin-top: 3em;
}

.detail-view-container {
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-top: 1em;
    border-radius: 0.6em 0.6em 0.6em 0.6em;
    padding-bottom: 40em;
}

.admin-form-edit-container {
    display: flex;
    flex-direction: row;
}

.admin-detail-view-edit-mode-button-container {
    transition: margin .5s;
    display: flex;
    gap: 1em;
    margin-top: 0.5em;
    margin-bottom: 1em;
}

.admin-detail-view-edit-mode-button-container button {
    margin: 0;
}

.activate-edit-mode-button {
    content: url('/public/icons/pencil_black.png');
    width: 2.5em;
    margin-left: 1em;
}

.activate-edit-mode-button-disabled {
    content: url('/public/icons/pencil_black.png');
    width: 2.5em;
    margin-left: 1em;
    opacity: 40%;
}

.activate-edit-mode-button:hover {
    content: url('/public/icons/pencil_blue.png');
}

.change-admin-password-label-container {
    margin-bottom: 3em;
}

.change-admin-new-password-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.change-admin-password-error-message {
    margin-top: 2em;
    color: red;
}

.password-checklist {
    margin-top: 3%;
}

@media screen and (max-width: 1000px) {
    .admin-detail-view-header {
        flex-direction: column;
        gap: 1em;
    }

    .admin-detail-view-header button {
        width: 80%;
    }

    .detail-view-container form div {
        width: 90%;
    }    
}

@media screen and (max-width: 500px) {
    .admin-detail-view-header h3 {
        font-size: 5vw;
    }

    .admin-detail-view-header button {
        font-size: 3vw;
    }
}