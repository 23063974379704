.change-mail-setting-header {
    display: flex;
    flex-direction: column;
    margin-top: 5em;
    margin-left: 20%;
}

.successful-changing-mail-message {
    color: green;
    margin-left: 20%;
}

@media screen and (max-width: 500px) {
    .change-mail-setting-header h3 {
        font-size: 5vw;
    }
}