.qr-tablet-register-container {
    display: flex;
    color: white;
    align-items: center;
    margin-left: 20%;
    margin-top: 3em;
    padding: 1.5em;
    background-color: #58726f;
    border-radius: 2em 2em 2em 2em;
    width: fit-content;
    gap: 1em;
    cursor: pointer;
}

.qr-tablet-register-information {
    display: flex;
    flex-direction: column;
}

.create-qr-account-container {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.qr-tablet-config-container {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    gap: 3em;
    margin-left: 20%;
}

.qr-tablet-config {
    display: flex;
    align-items: center;
    gap: 1em;
}

.qr-tablet-config h5 {
    margin-bottom: 0 !important;
}

.forward-img {
    height: 3em;
    width: auto;
}

.MuiButtonBase-root {
    text-transform: none !important;
    box-shadow: none !important;
}

@media screen and (max-width: 1200px) {
    .qr-tablet-register-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .qr-tablet-register-container {
        flex-direction: column;
        margin-left: 0;
        text-align: center;
    }

    .qr-tablet-config-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .qr-tablet-config-container {
        margin-top: 3em;
        margin-left: 0;
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .qr-tablet-register-container h4 {
        font-size: 5vw;
    }

    .qr-tablet-register-container h6 {
        font-size: 3vw;
    }

    .qr-tablet-config h5 {
        font-size: 3vw;
    }

    .qr-tablet-config button {
        width: 100%;
    }
}