.home-settings-container {
    display: flex;
    flex-direction: column;
    margin-top: 5em;
    margin-left: 20%;
}

.abonnement-button-container {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.abonnement-button-container button{
    padding: 20px 80px;
    font-size: 20px;
    text-transform: none ;
}

.divider-solid-home {
    border-top: 0.1em solid #000000;
    width: 80%;
}

.inner-box-flex {
    display: flex;
    position: relative;
    align-items: center;
}

.inner-box-flex ul {
    margin-bottom: 0;
}

.inner-box-link {
    text-decoration: none;
    color: #000000 !important;
}

.box-header {
    font-size: 20;
    font-weight: bold;
    margin-top: 2%;
    margin-left: 2%;
}

.circle-label {
    font-weight: bold;
    position: absolute;
    left: 25em;
    top: 5em;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.home-test-stage-info {
    margin-bottom: 2em;
}

.dashboard-container {
    display: flex;
    width: fit-content;
}

.circle-process {
    position: relative;
    margin-left: 1em;
}

.inner-box-flex .label-box p {
    margin-bottom: 0;
    margin-left: 0.4em;
}

@media screen and (max-width: 700px) {
    .home-settings-container {
        margin-left: auto;
        align-items: center;
        justify-content: center;
    }

    .home-test-stage-info h5 {
        text-align: center;
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (max-width: 500px) {
    .inner-box-flex {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .circle-process {
        position: relative;
    }

    .label-box {
        position: relative !important;
        top: -50px !important;
        left: 0.2em !important;
    }
}