.e-mail-reset-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
    padding-right: 5%;
    padding-left: 5%;
    width: 100%;
}

.e-mail-reset-container .mb-3 {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.e-mail-reset-container .form-label {
    width: fit-content;
}

.e-mail-reset-container button {
    margin-top: 3em;
}

.e-mail-reset-container h3 {
    margin-bottom: 3em;
}

.e-mail-reset-container h5 {
    margin-bottom: 3em;
}

.reset-mail-change-mail-successful {
    color: green;
}

.reset-mail-change-mail-error {
    color: red;
}

