.error-mail-validation-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 5em;
}

.error-mail-validation-message h3 {
    margin-top: 4em;
    color: red;
}